import { useParams, useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useMutation, useQueryClient } from 'react-query';

import ModalBasic from '../../../../components/ModalBasic';

export default function GenericModal({ modal, onClose }) {
  const navigate = useNavigate();
  const { id: positionId } = useParams();

  const { open, options } = modal;
  const {
    modalTitle,
    title,
    subtitle,
    motives = [],
    ps,
    cta,
    service,
    requestFeedbacks,
    goTo,
    question,
    dangerCTA,
  } = options || {};
  const queryClient = useQueryClient();

  const [refusal, setRefusal] = useState({
    refuseReason: '',
    feedback: '',
  });

  function mutateFn(data) {
    debugger;
    return toast.promise(service(data), requestFeedbacks);
  }

  const { mutate } = useMutation(mutateFn, {
    onSuccess: () => {
      queryClient.invalidateQueries();
      onClose();
      goTo && navigate(goTo);
    },
  });

  function onSubmit(e) {
    e.preventDefault();
    mutate({ positionId, refusal });
  }

  const selectedOtherRefuseReason = refusal.refuseReason === 'OTHER';

  return (
    <ModalBasic id="generic-modal" modalOpen={open} setModalOpen={onClose} title={modalTitle}>
      <form onSubmit={onSubmit}>
        <div className="px-4 py-2 sm:px-5 sm:py-4">
          {/* Modal content */}
          <div className="text-sm mb-4">
            <div className="space-y-2">
              <div className="font-medium text-slate-800 dark:text-slate-100 mb-2">{title}</div>
              <p>{subtitle}</p>
            </div>
          </div>
          <div className="space-y-2">
            {/* Checkbox */}
            <div className="py-1">
              <label className="block text-sm font-medium mb-1" htmlFor="motive">
                {question} <span className="text-rose-500">*</span>
              </label>
              <div className="flex flex-wrap items-center -m-2 mt-1">
                {motives.map((motive) => (
                  <div key={motive.id} className="m-2">
                    {/* Start */}
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="motive"
                        required
                        className="form-radio"
                        onChange={() => setRefusal({ ...refusal, refuseReason: motive.id })}
                        checked={motive.id === refusal.refuseReason}
                      />
                      <span className="text-sm ml-2">{motive.motive}</span>
                    </label>
                    {/* End */}
                  </div>
                ))}
              </div>
            </div>
            <div>
              <label className="block text-sm font-medium mb-1" htmlFor="feedback">
                {selectedOtherRefuseReason ? (
                  <span>
                    Qual foi o motivo? <span className="text-rose-500">*</span>
                  </span>
                ) : (
                  <span>Deseja adicionar algum comentário?</span>
                )}
              </label>
              <textarea
                id="feedback"
                name="feedback"
                onChange={(e) => setRefusal({ ...refusal, feedback: e.target.value })}
                className="form-textarea w-full px-2 py-1"
                rows="3"
                required={selectedOtherRefuseReason}
              ></textarea>
            </div>
            <div className="text-xs text-slate-500">{ps}</div>
          </div>
        </div>
        {/* Modal footer */}
        <div className="px-5 py-4 border-t border-slate-200 dark:border-slate-700">
          <div className="flex flex-wrap justify-end space-x-2">
            <button
              className="btn-sm border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-slate-600 dark:text-slate-300"
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                onClose();
              }}
            >
              Cancelar
            </button>
            <button
              className={`btn-sm ${dangerCTA ? 'bg-rose-500 hover:bg-rose-600' : 'bg-indigo-500 hover:bg-indigo-600'} text-white`}
            >
              {cta}
            </button>
          </div>
        </div>
      </form>
    </ModalBasic>
  );
}
