import React, { useState } from 'react';
import { toast } from 'react-toastify';
// import { Link } from 'react-router-dom';
// import EditMenu from '../../../components/DropdownEditMenu';
import ModalBlank from '../../../components/ModalBlank';
import { formatField } from '../../../partials/job/JobListItem';

//icons
import { FaCheck } from 'react-icons/fa';
import { RiMapPinTimeLine } from 'react-icons/ri';
import { MdAccessTimeFilled } from 'react-icons/md';
import { FaMoneyCheckDollar } from 'react-icons/fa6';
import { IoSchool, IoCloseSharp } from 'react-icons/io5';

// service
import { useMutation } from 'react-query';
import { createApplication } from '../../../common/services/app';

function Job({ job, onSubmit: onChange }) {
  const { timeToWork, Position } = job;
  const {
    id: positionId,
    city,
    experience_type,
    experience_level,
    salary_range,
    work_schedule,
    contract_type,
    education_level,
  } = Position;
  const [dangerModalOpen, setDangerModalOpen] = useState(false);
  const [successModalOpen, setSuccessModalOpen] = useState(false);

  function mutateFn(data) {
    const interested = data.interested;
    return toast.promise(createApplication(data), {
      pending: `${interested ? 'Enviando sua candidatura...' : 'Removendo a vaga da sua lista de oportunidades...'}`,
      success: `${interested ? 'Sua candidatura foi enviada com sucesso!' : 'Vaga removida da sua lista de oportunidades!'}`,
      error: `Ocorreu um erro ao ${interested ? 'enviar sua candidatura' : 'remover a vaga da sua lista de oportunidades'}. Por favor, tente novamente.`,
    });
  }

  const { mutate, isLoading } = useMutation(mutateFn, {
    onSuccess: () => {
      setDangerModalOpen(false);
      setSuccessModalOpen(false);
      onChange();
    },
  });

  function onSubmit({ interested }) {
    mutate({
      positionId,
      interested,
    });
  }

  return (
    <>
      <div className="col-span-full sm:col-span-6 xl:col-span-3 bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700 overflow-hidden">
        <div className="flex flex-col h-full">
          {/* Card Content */}
          <div className="grow flex flex-col p-5 lg:p-8 lg:py-6">
            {/* Card body */}
            <div className="grow">
              {/* Header */}
              <header className="mb-4">
                <div className="flex items-center justify-between">
                  <span className="hidden text-xs text-indigo-500 font-semibold uppercase tracking-widest mb-1">
                    Seja o primeiro a se candidatar!
                  </span>
                  <h3 className="text-lg text-slate-800 dark:text-slate-100 font-semibold mb-1">{experience_type}</h3>
                  {/* <EditMenu align="right" className="relative inline-flex shrink-0">
                    <li>
                      <Link
                        className="font-medium text-sm text-slate-600 dark:text-slate-300 hover:text-slate-800 dark:hover:text-slate-200 flex py-1 px-3"
                        to="#0"
                      >
                        Porquê estou vendo isso?
                      </Link>
                    </li>
                    <li>
                      <Link className="font-medium text-sm text-rose-500 hover:text-rose-600 flex py-1 px-3" to="#0">
                        Denunciar vaga
                      </Link>
                    </li>
                  </EditMenu> */}
                </div>

                <div className="text-sm">{city}</div>
              </header>
              {/* Features list */}
              <ul className="text-sm space-y-2 mb-5 dark:text-slate-300">
                <li className="flex items-center">
                  <svg
                    className="w-5 h-5 fill-current text-slate-400 dark:text-slate-500 shrink-0 mr-2"
                    viewBox="0 0 15 15"
                  >
                    <RiMapPinTimeLine />
                  </svg>
                  <div>{timeToWork} minutos do seu endereço</div>
                </li>
                <li className="flex items-center">
                  <svg
                    className="w-4 h-4 fill-current text-slate-400 dark:text-slate-500 shrink-0 mr-3"
                    viewBox="0 0 16 16"
                  >
                    <path d="M15 15V5l-5-5H2c-.6 0-1 .4-1 1v14c0 .6.4 1 1 1h12c.6 0 1-.4 1-1zM3 2h6v4h4v8H3V2z" />
                  </svg>
                  <div>Experiência desejada: {experience_level}</div>
                </li>
                <li className="flex items-center">
                  <svg
                    className="w-5 h-5 fill-current text-slate-400 dark:text-slate-500 shrink-0 mr-2"
                    viewBox="0 0 16 16"
                  >
                    <IoSchool />
                  </svg>
                  <div>Escolaridade mínima: {education_level}</div>
                </li>
                <li className="flex items-center">
                  <svg
                    className="w-5 h-5 fill-current text-slate-400 dark:text-slate-500 shrink-0 mr-2"
                    viewBox="0 0 16 16"
                  >
                    <MdAccessTimeFilled />
                  </svg>
                  <div>{work_schedule}</div>
                </li>
                <li className="flex items-center">
                  <svg
                    className="w-5 h-5 fill-current text-slate-400 dark:text-slate-500 shrink-0 mr-2"
                    viewBox="0 0 16 16"
                  >
                    <FaMoneyCheckDollar />
                  </svg>
                  <div>
                    Salário entre {salary_range} ({formatField(contract_type)})
                  </div>
                </li>
              </ul>
            </div>
          </div>
          {/* Card footer */}
          <div className="border-t border-slate-200 dark:border-slate-700">
            <div className="flex divide-x divide-slate-200 dark:divide-slate-700">
              <button
                className="block flex-1 text-center text-sm text-slate-600 hover:text-slate-800 dark:text-slate-300 dark:hover:text-slate-200 font-medium px-3 py-4 group"
                onClick={(e) => {
                  e.stopPropagation();
                  setDangerModalOpen(true);
                }}
              >
                <div className="flex items-center justify-center">
                  <svg
                    className="w-5 h-5 fill-current text-slate-400 dark:text-rose-600 group-hover:text-rose-500 shrink-0 mr-2"
                    viewBox="0 0 14 14"
                  >
                    <IoCloseSharp />
                  </svg>
                  <span>Não tenho interesse</span>
                </div>
              </button>
              <button
                className="block flex-1 text-center text-sm text-emerald-600 hover:text-emerald-500 dark:hover:text-emerald-400 font-medium px-3 py-4"
                onClick={(e) => {
                  e.stopPropagation();
                  setSuccessModalOpen(true);
                }}
              >
                <div className="flex items-center justify-center">
                  <svg className="w-5 h-5 fill-current shrink-0 mr-2" viewBox="0 0 16 16">
                    <FaCheck />
                  </svg>
                  <span>Tenho interesse!</span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Success Modal */}
      <ModalBlank id="success-modal" modalOpen={successModalOpen} setModalOpen={setSuccessModalOpen}>
        <div className="p-5 flex space-x-4">
          {/* Icon */}
          <div className="w-10 h-10 rounded-full flex items-center justify-center shrink-0 bg-emerald-100 dark:bg-emerald-400/30">
            <svg className="w-4 h-4 shrink-0 fill-current text-emerald-500" viewBox="0 0 16 16">
              <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zM7 11.4L3.6 8 5 6.6l2 2 4-4L12.4 6 7 11.4z" />
            </svg>
          </div>
          {/* Content */}
          <div>
            {/* Modal header */}
            <div className="mb-2">
              <div className="text-lg font-semibold text-slate-800 dark:text-slate-100">
                Deseja se candidatar a esta vaga?
              </div>
            </div>
            {/* Modal content */}
            <div className="text-sm mb-10">
              <div className="space-y-2">
                <p>
                  Ao clicar em <b>"Quero me candidatar"</b>, você estará participando do processo seletivo para esta
                  vaga e <b>não poderá se candidatar a outras vagas</b> até que este processo termine.
                </p>
              </div>
            </div>
            {/* Modal footer */}
            <div className="flex flex-wrap justify-end gap-2">
              <button
                className="btn-sm border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-slate-600 dark:text-slate-300"
                onClick={(e) => {
                  e.stopPropagation();
                  setSuccessModalOpen(false);
                }}
              >
                Cancelar
              </button>
              <button
                className="btn-sm bg-indigo-500 hover:bg-indigo-600 text-white"
                onClick={() => onSubmit({ interested: true })}
              >
                Quero me candidatar
              </button>
            </div>
          </div>
        </div>
      </ModalBlank>
      <ModalBlank id="danger-modal" modalOpen={dangerModalOpen} setModalOpen={setDangerModalOpen}>
        <div className="p-5 flex space-x-4">
          {/* Icon */}
          <div className="w-10 h-10 rounded-full flex items-center justify-center shrink-0 bg-rose-100 dark:bg-rose-500/30">
            <svg className="w-4 h-4 shrink-0 fill-current text-rose-500" viewBox="0 0 16 16">
              <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm0 12c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1zm1-3H7V4h2v5z" />
            </svg>
          </div>
          {/* Content */}
          <div>
            {/* Modal header */}
            <div className="mb-2">
              <div className="text-lg font-semibold text-slate-800 dark:text-slate-100">
                Tem certeza que não tem interesse nesta vaga?
              </div>
            </div>
            {/* Modal content */}
            <div className="text-sm mb-10">
              <div className="space-y-2">
                <p>
                  Ao clicar em <b>"Não tenho interesse"</b>, não mostraremos mais esta vaga para você.
                </p>
              </div>
            </div>
            {/* Modal footer */}
            <div className="flex flex-wrap justify-end gap-2">
              <button
                className="btn-sm border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-slate-600 dark:text-slate-300"
                onClick={(e) => {
                  e.stopPropagation();
                  setDangerModalOpen(false);
                }}
              >
                Voltar e me candidatar
              </button>
              <button
                disabled={isLoading}
                className="btn-sm bg-rose-500 hover:bg-rose-600 text-white"
                onClick={() => onSubmit({ interested: false })}
              >
                Não tenho interesse
              </button>
            </div>
          </div>
        </div>
      </ModalBlank>
    </>
  );
}

export default Job;
