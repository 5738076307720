import React, { useState } from 'react';

//Components
import Address from '../components/Address';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import ModalBasic from '../../../components/ModalBlank';

export default function EditAddress({ value, isFetching, onSubmit }) {
  const title = 'Editar local de trabalho';
  const [showModal, setShowModal] = useState(false);

  function getAddress() {
    if (value?.street) return value;
  }

  function mountAddress() {
    if (isFetching && !value?.street) return 'Carregando...';
    const { street, number, neighborhood, city, state, cep } = getAddress();
    return `${street}, ${number} - ${neighborhood}, ${city} - ${state}, ${cep}`;
  }

  function handleAddressSubmit(address) {
    delete address.id;
    delete address.createdAt;
    delete address.deletedAt;
    delete address.updatedAt;
    onSubmit(address);
  }

  const hasChanged = !value?.id;

  return (
    <>
      <Input id="Address" name="Address" disabled value={mountAddress()} required />
      <Button
        type="button"
        variant="secondary"
        className="ml-3"
        onClick={(e) => {
          e.stopPropagation();
          if (hasChanged) {
            onSubmit({});
          } else {
            setShowModal(true);
          }
        }}
      >
        {hasChanged ? 'Limpar' : 'Alterar'}
      </Button>
      <ModalBasic id="edit-address" title={title} modalOpen={showModal} setModalOpen={setShowModal}>
        {/* Modal content */}
        <div className="px-5 py-4">
          <div className="text-sm">
            <div className="font-medium text-slate-800 dark:text-slate-100 mb-3">{title}</div>
          </div>
          <Address id="edit-address-form" value={getAddress()} display hideActions onSubmit={handleAddressSubmit} />
        </div>
        {/* Modal footer */}
        <div className="px-5 py-4 border-t border-slate-200 dark:border-slate-700">
          <div className="flex flex-wrap justify-end space-x-2">
            <button
              type="button"
              className="btn-sm border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-slate-600 dark:text-slate-300"
              onClick={(e) => {
                e.stopPropagation();
                setShowModal(false);
              }}
            >
              Cancelar
            </button>
            <Button
              size="sm"
              onClick={(e) => {
                e.preventDefault();
                document.getElementById('submit-hidden-button').click();
                const form = document.getElementById('edit-address-form');
                // check if some field is invalid
                if (!form.checkValidity()) {
                  form.reportValidity();
                  onSubmit({});
                  return;
                } else {
                  setShowModal(false);
                }
              }}
            >
              Salvar alteração
            </Button>
          </div>
        </div>
      </ModalBasic>
    </>
  );
}
