import { Suspense, useContext } from 'react';
import { UserContext } from './common/hooks/useUser';
import { Routes, Route, Navigate } from 'react-router-dom';

// TrabalhaAI Pages
import Login from './pages/app/Login';
import Positions from './pages/app/positions/Positions';
import Jobs from './pages/app/jobs/Jobs';
import Position from './pages/app/positions/JobPost';
import NewPosition from './pages/app/positions/NewPosition';
import LoginPhone from './pages/app/LoginPhone';
import AccessCode from './pages/app/AccessCode';
import Onboarding from './pages/app/onboarding';
import PageNotFound from './pages/utility/PageNotFound';
import Pay from './pages/app/positions/pay/Pay';
import Transactions from './pages/finance/Transactions';

import Account from './pages/settings/Account';
import Notifications from './pages/settings/Notifications';
import Apps from './pages/settings/Apps';
import Plans from './pages/settings/Plans';
import Billing from './pages/settings/Billing';
import Feedback from './pages/settings/Feedback';

// TrabalhaAI Components
import Loading from './components/app/Loading';
import AuthRoute from './components/app/AuthRoute';
import MaxPositionsModal from './pages/app/components/MaxPositionsModal';
import PromoFirstPurchaseModal from './pages/app/components/PromoFirstPurchaseModal';

export default function AppRouter() {
  const { isCandidate, isCompany, isFetching, firebaseData } = useContext(UserContext);
  const { isAuthenticated } = firebaseData;

  if (isFetching) return <Loading />;

  return (
    <Suspense fallback={<Loading />}>
      {isCompany && <MaxPositionsModal />}
      {isCompany && <PromoFirstPurchaseModal />}
      <Routes>
        {!isAuthenticated && <Route path="*" element={<Navigate to="/auth/login" />} />}
        <Route>
          {/* Company */}
          {isCompany && <Route path="/" element={<Navigate to="/positions" />} />}
          {isCompany && <Route path="/positions" element={<Positions />} />}
          {isCompany && <Route path="/positions/new" element={<NewPosition />} />}
          {isCompany && <Route path="/positions/:id" element={<Position />} />}
          {isCompany && <Route path="/positions/:id/pay" element={<Pay />} />}
          {isCompany && <Route path="/finance/transactions" element={<Transactions />} />}

          {/* Candidate */}
          {isCandidate && <Route path="/" element={<Navigate to="/jobs" />} />}
          {isCandidate && <Route path="/jobs" element={<Jobs />} />}

          <Route path="/onboarding" element={<Onboarding />} />

          {/* Settings */}
          <Route path="/settings/account" element={<Account />} />
          <Route path="/settings/notifications" element={<Notifications />} />
          <Route path="/settings/apps" element={<Apps />} />
          <Route path="/settings/plans" element={<Plans />} />
          <Route path="/settings/billing" element={<Billing />} />
          <Route path="/settings/feedback" element={<Feedback />} />
        </Route>

        <Route element={<AuthRoute />}>
          <Route path="/auth/login" element={<Login />} />
          <Route path="/auth/login/phone" element={<LoginPhone />} />
          <Route path="/auth/login/phone/access-code" element={<AccessCode />} />
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Suspense>
  );
}
