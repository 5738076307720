import React, { useState } from 'react';

import { useQuery } from 'react-query';
import { getBestPosition } from '../../../common/services/app';

import Header from '../../../partials/Header';
import Sidebar from '../../../partials/Sidebar';

import Job from './Job';
import Loading from '../../../components/app/Loading';
import LeftMenu from './LeftMenu';
import JobsEmpty from './JobsEmptyState';

function Feed() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { isLoading, isFetched, data = {}, refetch } = useQuery('getBestPosition', getBestPosition);

  const { bestMatch, application } = data;
  const hasActiveApplication = !!(application && application.status === 'PENDING');

  return (
    <div className="flex h-[100dvh] overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main className="grow">
          <div className="px-4 sm:px-6 lg:px-8 py-8 md:py-0 w-full max-w-9xl mx-auto">
            <div className="xl:flex">
              {/* Left + Middle content */}
              <div className="md:flex flex-1">
                {/* Left content */}
                <LeftMenu />

                {/* Middle content */}
                <div className="flex-1 md:ml-8 xl:mx-4 2xl:mx-8">
                  <div className="md:py-8 md:mt-16">
                    {isLoading && <Loading />}
                    {isFetched &&
                      (bestMatch?.Position ? (
                        <Job job={bestMatch} onSubmit={refetch} />
                      ) : (
                        <JobsEmpty hasActiveApplication={hasActiveApplication} />
                      ))}
                  </div>
                </div>
              </div>

              {/* Right content */}
              <div className="w-full hidden xl:block xl:w-72"></div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default Feed;
