import React, { useState, useRef } from 'react';
import { useQuery } from 'react-query';
import { Link, useParams } from 'react-router-dom';

import Sidebar from '../../../partials/Sidebar';
import Header from '../../../partials/Header';
import Loading from '../../../components/app/Loading';
import { formatField, formatWorkSchedule } from '../../../partials/job/JobListItem';
import { formatDateToShortDate } from '../../../utils/Utils';

import { getPosition } from '../../../common/services/app';
import Candidates from './CandidatesSidebar/Candidates';
import JobPostCandidates from './job-post-candidates/JobPostCandidates';
import Actions from './Actions/Actions';
import ReleaseCandidates from './ReleaseCandidates';
import PaymentStatus from './CandidatesSidebar/PaymentStatus';

function JobPost() {
  const { id } = useParams();
  const candidatesRef = useRef();
  const { data, isLoading } = useQuery(['position', id], getPosition);

  const [planModalOpen, setPlanModalOpen] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  function openReleaseCandidatesModal(e) {
    debugger
    e.stopPropagation();
    setPlanModalOpen(true);
  }

  function onShowDetails() {
    candidatesRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  return (
    <div className="flex h-[100dvh] overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main className="grow">
          {isLoading ? (
            <Loading />
          ) : (
            <div className="px-4 sm:px-6 lg:px-8 py-8 w-full">
              {/* Page content */}
              <div className="flex justify-between max-w-5xl mx-auto flex-col lg:flex-row lg:space-x-8 xl:space-x-16">
                {/* Content */}
                <div className="w-full">
                  <div className="mb-6">
                    <Link
                      className="btn-sm px-3 bg-white dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-slate-600 dark:text-slate-300"
                      to="/positions"
                    >
                      <svg
                        className="fill-current text-slate-400 dark:text-slate-500 mr-2"
                        width="7"
                        height="12"
                        viewBox="0 0 7 12"
                      >
                        <path d="M5.4.6 6.8 2l-4 4 4 4-1.4 1.4L0 6z" />
                      </svg>
                      <span>Voltar para minhas vagas</span>
                    </Link>
                  </div>
                  <div className="text-sm text-slate-500 dark:text-slate-400 italic mb-2">
                    Publicado em {formatDateToShortDate(data?.createdAt)}
                  </div>
                  <header className="mb-4">
                    {/* Title */}
                    <h1 className="text-2xl md:text-3xl text-slate-800 dark:text-slate-100 font-bold">
                      {data?.experience_type}
                    </h1>
                  </header>

                  {/* Tags */}
                  <div className="mb-6 hidden">
                    <div className="flex flex-wrap items-center -m-1">
                      <div className="m-1">
                        {['contract_type'].map((tag) => (
                          <span
                            key={tag}
                            className="text-xs inline-flex font-medium rounded-full text-center px-2.5 py-1 bg-indigo-100 dark:bg-indigo-500/30 text-indigo-600 dark:text-indigo-400"
                          >
                            {formatField(data && data[tag])}
                          </span>
                        ))}
                      </div>
                      <span className="text-xs inline-flex font-medium bg-indigo-100 dark:bg-indigo-500/30 text-indigo-600 dark:text-indigo-400 rounded-full text-center px-2.5 py-1">
                        {formatWorkSchedule(data?.work_schedule)}
                      </span>
                    </div>
                  </div>

                  {/* Company information (mobile) */}
                  <Actions
                    status={data.status}
                    openReleaseCandidatesModal={openReleaseCandidatesModal}
                    className="bg-white dark:bg-slate-800 p-5 shadow-lg rounded-sm border border-slate-200 mb-6 lg:hidden"
                  />
                  <PaymentStatus className="lg:hidden" order={data?.order} />
                  <Candidates
                    status={data.status}
                    positionId={id}
                    onShowDetails={onShowDetails}
                    canReleaseCandidates={data.canReleaseCandidates}
                    canViewCandidates={data.canViewCandidates}
                    className="bg-white dark:bg-slate-800 p-5 shadow-lg rounded-sm border border-slate-200 mb-6 lg:hidden"
                  />

                  <hr className="my-6 border-t border-slate-200 dark:border-slate-700" />

                  {/* About You */}
                  <div className="d-flex flex-col space-y-1">
                    <h2 className="text-xl leading-snug text-slate-800 dark:text-slate-100 font-bold mb-2">
                      Informações da vaga
                    </h2>
                    <div className="d-flex flex-col space-y-3">
                      <div className="text-md">
                        <h3 className="font-medium text-slate-800 dark:text-slate-100">Local de trabalho</h3>
                        <span>{data?.city}</span>
                      </div>
                      <div className="text-md">
                        <h3 className="font-medium text-slate-800 dark:text-slate-100">Jornada de trabalho</h3>
                        <span>{data?.work_schedule}</span>
                      </div>
                      <div className="text-md">
                        <h3 className="font-medium text-slate-800 dark:text-slate-100">Remuneração</h3>
                        <span>{data?.salary_range}</span>
                      </div>
                      <div className="text-md">
                        <h3 className="font-medium text-slate-800 dark:text-slate-100">Experiência</h3>
                        <span>{data?.experience_level}</span>
                      </div>
                      <div className="text-md">
                        <h3 className="font-medium text-slate-800 dark:text-slate-100">Regime de trabalho</h3>
                        <span>{data?.contract_type}</span>
                      </div>
                    </div>
                  </div>

                  {/* Candidatures */}
                  {data.activeCandidatures > 0 && (
                    <>
                      <hr ref={candidatesRef} className="my-6 border-t border-slate-200 dark:border-slate-700" />
                      <JobPostCandidates
                        positionId={id}
                        positionName={data.experience_type}
                        status={data.status}
                        canViewCandidates={data.canViewCandidates}
                        canReleaseCandidates={data.canReleaseCandidates}
                        openReleaseCandidatesModal={openReleaseCandidatesModal}
                      />
                    </>
                  )}

                  {/* Apply section */}
                  <div className="mt-6 hidden">
                    <p className="font-medium italic mb-6">Do you have what it takes?</p>
                    <div className="flex justify-between items-center">
                      {/* Apply button */}
                      <button className="btn bg-indigo-500 hover:bg-indigo-600 text-white whitespace-nowrap">
                        Apply Today -&gt;
                      </button>
                      {/* Share */}
                      <div className="flex items-center">
                        <div className="text-sm text-slate-500 dark:text-slate-400 italic mr-4">Compartilhar:</div>
                        <div className="flex items-center space-x-3">
                          <button className="text-slate-400 dark:text-slate-500 hover:text-indigo-500 dark:hover:text-indigo-500">
                            <span className="sr-only">Share on Twitter</span>
                            <svg
                              className="w-4 h-4 fill-current"
                              viewBox="0 0 16 16"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M16 3.5c-.6.3-1.2.4-1.9.5.7-.4 1.2-1 1.4-1.8-.6.4-1.3.6-2.1.8-.6-.6-1.5-1-2.4-1-1.7 0-3.2 1.5-3.2 3.3 0 .3 0 .5.1.7-2.7-.1-5.2-1.4-6.8-3.4-.3.5-.4 1-.4 1.7 0 1.1.6 2.1 1.5 2.7-.5 0-1-.2-1.5-.4 0 1.6 1.1 2.9 2.6 3.2-.3.1-.6.1-.9.1-.2 0-.4 0-.6-.1.4 1.3 1.6 2.3 3.1 2.3-1.1.9-2.5 1.4-4.1 1.4H0c1.5.9 3.2 1.5 5 1.5 6 0 9.3-5 9.3-9.3v-.4c.7-.5 1.3-1.1 1.7-1.8Z" />
                            </svg>
                          </button>
                          <button className="text-slate-400 dark:text-slate-500 hover:text-indigo-500 dark:hover:text-indigo-500">
                            <span className="sr-only">Share on Facebook</span>
                            <svg
                              className="w-4 h-4 fill-current"
                              viewBox="0 0 16 16"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M6.023 16 6 9H3V6h3V4c0-2.7 1.672-4 4.08-4 1.153 0 2.144.086 2.433.124v2.821h-1.67c-1.31 0-1.563.623-1.563 1.536V6H13l-1 3H9.28v7H6.023Z" />
                            </svg>
                          </button>
                          <button className="text-slate-400 dark:text-slate-500 hover:text-indigo-500 dark:hover:text-indigo-500">
                            <span className="sr-only">Share on Linkedin</span>
                            <svg
                              className="w-4 h-4 fill-current"
                              viewBox="0 0 16 16"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M0 1.146C0 .514.53 0 1.182 0h13.635C15.471 0 16 .513 16 1.146v13.708c0 .633-.53 1.146-1.183 1.146H1.182C.53 16 0 15.487 0 14.854V1.146ZM4.862 13.39V6.187H2.468v7.203h2.394ZM3.666 5.203c.834 0 1.354-.553 1.354-1.244-.016-.707-.52-1.245-1.338-1.245-.82 0-1.355.538-1.355 1.245 0 .691.52 1.244 1.323 1.244h.015Zm2.522 8.187h2.394V9.368c0-.215.015-.43.078-.584.173-.43.567-.876 1.229-.876.866 0 1.213.66 1.213 1.629v3.853h2.394V9.26c0-2.213-1.181-3.242-2.756-3.242-1.292 0-1.86.722-2.174 1.213h.016V6.187H6.188c.03.676 0 7.203 0 7.203Z" />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr className="my-6 border-t border-slate-200 dark:border-slate-700 hidden" />
                </div>

                {/* Sidebar */}
                <div className="hidden lg:block space-y-4">
                  {/* Company information (desktop) */}

                  {/* Candidatos (Desktop) */}
                  <Actions status={data.status} openReleaseCandidatesModal={openReleaseCandidatesModal} />
                  <PaymentStatus order={data?.order} />
                  <Candidates
                    status={data.status}
                    positionId={id}
                    onShowDetails={onShowDetails}
                    canViewCandidates={data.canViewCandidates}
                    canReleaseCandidates={data.canReleaseCandidates}
                    candidatures={data.candidatures}
                  />
                </div>
              </div>
            </div>
          )}
        </main>
        <ReleaseCandidates positionId={id} setPlanModalOpen={setPlanModalOpen} planModalOpen={planModalOpen} />
      </div>
    </div>
  );
}

export default JobPost;
