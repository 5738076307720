import React, { createContext, useState, useEffect } from 'react';
import auth from '../services/auth/firebase.ts';
import { useQuery } from 'react-query';
import { getUser } from '../services/app.ts';
import Loading from '../../components/app/Loading.jsx';
import { Navigate, useLocation } from 'react-router-dom';

const UserContext = createContext(null);

const UserProvider = ({ children }) => {
  const { pathname } = useLocation();
  const isOnboardingRoute = pathname === '/onboarding';
  const [firebaseData, setFirebaseData] = useState({
    data: null,
    isFetched: false,
    isAuthenticated: false,
  });
  const {
    data: user,
    isFetched: isUserFetched,
    isError,
    isFetching,
  } = useQuery('user', getUser, {
    retry: false,
    enabled: firebaseData.isAuthenticated,
    onSuccess: ({ entity }) => {
      sessionStorage.setItem('entity', entity);
    },
  });

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((data) => {
      setFirebaseData((prevState) => ({ ...prevState, data, isFetched: true, isAuthenticated: !!data }));
    });
    return () => unsubscribe(); // Cleanup subscription
  }, []);

  const { isFetched, data } = firebaseData;

  if ((!isUserFetched && isError) || !isFetched) return <Loading />;
  if (data && !data.displayName && !isOnboardingRoute) return <Navigate to="/onboarding" />;

  const entityType = sessionStorage.getItem('entity');

  return (
    <UserContext.Provider
      value={{
        user,
        isUserFetched,
        firebaseData,
        isFetching: !entityType && isFetching,
        isCompany: entityType === 'company',
        isCandidate: entityType === 'candidate',
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export { UserProvider, UserContext };
