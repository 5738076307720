import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { getPositions } from '../../../common/services/app';

import Sidebar from '../../../partials/Sidebar';
import Section from '../components/Section';
import Header from '../../../partials/Header';
import EmptyState from './PositionsEmptyState';
import JobListing from './JobListing';
import NewPositionButton from './NewPositionButton';
import { showPromoModal } from '../components/PromoFirstPurchaseModal';

function Positions() {
  const [page, setPage] = useState(1);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const {
    data = { positions: [] },
    isFetched,
    ...query
  } = useQuery('positions', () => getPositions({ page }), {
    keepPreviousData: false,
    onSuccess: (data) => {
      const completeCandidates = data.positions.find(({ status }) => status === 'CANDIDATES_REVIEW');
      if (completeCandidates) {
        showPromoModal(`/positions/${completeCandidates.id}`);
      }
    },
  });

  const backgroundColor = isFetched && data?.length === 0 ? '' : 'bg-slate-100';
  const maxPositions = data.positions.filter(({ status }) => !['CANCELLED', 'CLOSED'].includes(status)).length > 2;

  return (
    <div className="flex h-[100dvh] overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden bg-white dark:bg-slate-900">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main className={`grow ${backgroundColor} transition-all`}>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            {/* Page header */}
            <div className="sm:flex sm:justify-between sm:items-center mb-8">
              {/* Left: Title */}
              <div className="mb-4 sm:mb-0">
                <h1 className="text-2xl md:text-3xl text-slate-800 dark:text-slate-100 font-bold">Vagas ✨</h1>
              </div>

              {/* Right: Actions */}
              <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
                {/* Add board button */}
                <NewPositionButton disabled={maxPositions} />
              </div>
            </div>
            <Section {...query} isEmpty={!data.totalItems} EmptyFeedback={EmptyState}>
              <JobListing {...data} page={page} setPage={setPage} />
            </Section>
          </div>
        </main>
      </div>
    </div>
  );
}

export default Positions;
