import { closePosition, deletePosition } from '../../../../common/services/app';

export const modals = {
  CANCEL: {
    modalTitle: 'Cancelar vaga?',
    title: 'Que pena que você não deseja mais contratar um profissional para esta vaga.',
    subtitle: 'Pode nos contar o motivo? Assim conseguimos seguir melhorando!',
    ps: 'Esta vaga não poderá ser reaberta, mas caso deseje, você poderá criar uma nova vaga no futuro.',
    motives: [
      { id: 'TIRED_WAITING', motive: 'Cansei de esperar' },
      { id: 'HIRED_OTHER_WAY', motive: 'Contratei a pessoa de outra forma' },
      { id: 'EXPENSIVE', motive: 'Achei caro para liberar os contatos' },
      { id: 'DONT_LIKE', motive: 'Não gostei dos candidatos' },
      { id: 'OTHER', motive: 'Outro motivo' },
    ],
    dangerCTA: true,
    cta: 'Cancelar vaga',
    question: 'Motivo',
    service: deletePosition,
    requestFeedbacks: {
      pending: 'Guardando informações...',
      success: 'Vaga cancelada com sucesso',
      error: 'Ocorreu um erro ao cancelar a vaga. Tente novamente mais tarde.',
    },
    goTo: '/positions',
  },
  FINISH: {
    modalTitle: 'Encerrar vaga?',
    title:
      'Obrigado por ultilizar o Trabalha AI! Ficamos felizes em te ajudar a encontrar o profissional ideal para a sua vaga.',
    subtitle: 'Responda algumas perguntas para nos ajudar a melhorar.',
    ps: 'Ao encerrar a vaga você ainda poderá acessar os candidatos que se inscreveram.',
    motives: [
      { id: 'HIRE', motive: 'Contratei um candidato' },
      { id: 'NOT_HIRE', motive: 'Não contratei nenhum candidato' },
      { id: 'DONT_LIKE', motive: 'Não gostei dos candidatos' },
      { ID: 'WITHOUT_REPLIES', motive: 'Não recebi respostas' },
      { id: 'OTHER', motive: 'Outro motivo' },
    ],
    cta: 'Encerrar vaga',
    question: 'Conte-nos como foi a sua experiência',
    service: closePosition,
    requestFeedbacks: {
      pending: 'Guardando informações...',
      success: 'Vagas encerrada com sucesso',
      error: 'Ocorreu um erro ao encerrar a vaga. Tente novamente mais tarde.',
    },
    goTo: '/positions',
  },
};
